import prof from './assets/prof.png'

function App() {
  return (
  <div class="flex h-screen bg-[#0C000F] text-white">
    <div class="m-auto text-center px-10">

      <div class="flex items-center justify-center max-w-300 max-h-300 md:max-w-400 md:max-h-400 bg-[#0C000F] border-2 border-slate-400 rounded-full">
          <img src={prof} alt="profile pic" className="w-[200px] h-[200px] md:w-[300px] md:h-[300px] rounded-full hover:scale-95 duration-500 !z-10 md:mx-20 md:my-20 mx-10 my-10"/>
      </div>

      <h1 className="text-6xl md:text-8xl font-semibold tracking-wide pt-20">Verzo</h1>
      <div className="flex justify-center text-lg md:text-2xl">
        <h2>Graphic Design</h2>
        <h2 className="pl-2 text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-purple-800">for web3</h2>
      </div>
      <h3 className="pt-10">Starting soon</h3>
    </div>
  </div>
  );
}

export default App;
